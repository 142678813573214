<template>
  <div>
    <el-dialog
      :title="isEdit ? '修改商家' : '新建商家'"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form
        ref="businessForm"
        :model="businessForm"
        label-width="200px"
        size="small"
        :rules="rules"
      >
        <el-form-item
          label="一级商家"
          label-width="100px"
          prop="senior_id"
          style="margin-bottom: 15px"
        >
          <el-select
            style="width: 100%"
            v-model="businessForm.senior_id"
            :disabled="isEdit"
            :remote-method="seniorRemoteMethod"
            :loading="seniorLoading"
            placeholder="请输入关键词"
            @change="onSeniorChange"
            @clear="onSeniorClear"
            clearable
            filterable
            remote
            ref="cascader"
            @visible-change="(v) => visibleChange(v, 'cascader')"
          >
            <el-option
              v-for="item in seniorList"
              :key="item.senior_id"
              :label="item.senior_name"
              :value="item.senior_id"
            >
              <span class="check" />
              <span style="margin-left: 8px">{{ item.senior_name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商家主体"
          label-width="100px"
          prop="entity_id"
          style="margin-bottom: 15px"
        >
          <el-select
            style="width: 100%"
            v-model="businessForm.entity_id"
            :remote-method="entityRemoteMethod"
            :loading="entityLoading"
            placeholder="请输入关键词"
            @change="onEntityChange"
            @clear="onEntityClear"
            clearable
            filterable
            remote
          >
            <el-option
              v-for="item in entityList"
              :key="item.entity_id"
              :label="item.entity_name"
              :value="item.entity_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商家名称"
          label-width="100px"
          prop="full_name"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="businessForm.full_name"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="法律主体"
          label-width="100px"
          prop="contract_layer_name"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="businessForm.contract_layer_name"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商家电话"
          label-width="100px"
          prop="service_phone"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="businessForm.service_phone"
            clearable
            maxlength="11"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="客服电话"
          label-width="100px"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="businessForm.customer_service_phone"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <div>
          <el-divider content-position="left">
            <h3>报价</h3>
          </el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以配置报价套餐"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.manage_package">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="businessForm.manage_package == 1">
              <el-form-item
                label="是否可以设置机构附加服务费"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.is_business_fee_extend">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="businessForm.manage_package == 1">
              <el-form-item
                label="是否可调整服务费承担方"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.discount_bill">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="businessForm.manage_package == 1">
              <el-form-item
                label="是否可以设置首期支付天数"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.first_pay_day">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-divider content-position="left">
            <h3>产品</h3>
          </el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以添加产品"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.manage_product">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-divider content-position="left">
            <h3>账单</h3>
          </el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以输入身份证"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.skip_id_card_image" :disabled="businessForm.skip_contact == 1">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否跳过人脸" style="margin-bottom: 15px">
                <el-radio-group v-model="businessForm.skip_ocr" :disabled="businessForm.skip_contact == 1">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="是否跳过绑卡" style="margin-bottom: 15px">
                <el-radio-group v-model="businessForm.skip_bind_bank" :disabled="businessForm.skip_contact == 1" >
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否自动确认" style="margin-bottom: 15px">
                <el-radio-group v-model="businessForm.auto_confirm" :disabled="businessForm.skip_contact == 1">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="逾期费是否减免" style="margin-bottom: 15px">
                <el-radio-group v-model="businessForm.exceed_fee">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="延期管理费是否减免"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.extend_fee">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以设置延期"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.dalay_day">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="是否可以修改逾期宽限期"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.exceed_fee_day">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以申请退费"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.refund_bill">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="是否可以修改还款日期"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.is_allow_change_day" :disabled="businessForm.skip_contact == 1">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="申办年龄"
                label-width="100px"
                style="margin-bottom: 15px"
                prop="min_age"
              >
                <el-input
                  style="width: 100px"
                  v-model="businessForm.min_age"
                  placeholder="请输入最小年龄"
                />
                -
                <el-input
                  style="width: 100px"
                  v-model="businessForm.max_age"
                  placeholder="请输入最大年龄"
                />
              </el-form-item>
            </el-col>
          <el-col :span="12">
            <el-form-item
                label="是否取消填写个人信息"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.skip_contact" @input="is_skip">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="是否可以今日还款"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.today_pay">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
          </el-col>
          </el-row>
        </div>
        <div>
          <el-divider content-position="left">
            <h3>快捷支付费用项目</h3>
          </el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以申请快捷支付"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.is_support_fast_pay">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以申请先学后付"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.is_support_xxhf">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-divider content-position="left">
            <h3>其他</h3>
          </el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否可以申请助梦金"
                style="margin-bottom: 15px"
              >
                <el-radio-group v-model="businessForm.is_support_zmj">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('businessForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <seniorModal ref="seniorModal" @getNewSeniorName="getNewSeniorName" />
  </div>
</template>

<script>
import { editBusiness, createBusiness, infoBusiness } from "@/api/business.js";
import { selectSenior, selectEntity } from "@/api/selectfun.js";
import seniorModal from "../../senior/components/senior-modal.vue";
import { getAuth } from "@/utils/index.js";
export default {
  name: "operating-record",
  components: { seniorModal },
  data() {
    let checkAge = (rule, value, callback) => {
      if (value != "" && this.businessForm.max_age != "") {
        console.log(value);
        console.log(this.businessForm.max_age);
        console.log(value > this.businessForm.max_age);
        if (value > this.businessForm.max_age) {
          callback(new Error("最大年龄限制不能小于最小年龄限制"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入申办年龄"));
      }
    };
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      dialogFormVisible: false,
      isEdit: false,
      title: "新增商家",
      businessForm: {
        exceed_fee: 0,
        extend_fee: 0,
        auto_confirm: 0,
        skip_bind_bank: 0,
        skip_ocr: 0,
        skip_id_card_image: 0,
        discount_bill: 0,
        refund_bill: 0,
        refund_bill_num: 0,
        first_pay_day: 0,
        dalay_day: 0,
        manage_product: 0,
        is_business_fee_extend: 0,
        manage_package: 0,
        exceed_fee_day: 0,
        min_age: 18,
        max_age: 55,
        customer_service_phone:'',
        is_allow_change_day: 0,
        is_support_zmj: 0,
        contract_layer_name: '',
        skip_contact: 0
      },
      business_id: "",
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      rules: {
        senior_id: [
          { required: true, message: "请选择一级商家", trigger: "change" },
        ],
        entity_id: [
          { required: true, message: "请选择商家主体", trigger: "change" },
        ],
        full_name: [
          { required: true, message: "请输入商家名称", trigger: "blur" },
        ],
        contract_layer_name: [
          { required: false, message: "请输入法律主体", trigger: "blur" },
        ],
        service_phone: [
          { required: true, message: "请输入客服电话", trigger: "blur" },
        ],
        min_age: [
          {
            type: "number",
            validator: checkAge,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async isShow(type, val) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.isEdit = true;
        this.business_id = val.business_id;
        this.getInfoBusiness();
      } else {
        this.isEdit = false;
        this.business_id = "";
      }
    },
    is_skip(val) {
      if (val == 1) {
        this.businessForm.skip_ocr = 1
        this.businessForm.skip_id_card_image = 1
        this.businessForm.auto_confirm = 1
        this.businessForm.skip_bind_bank = 0
        this.businessForm.is_allow_change_day = 1
      }
    },
    visibleChange(visible, refName) {
      if (visible) {
        const ref = this.$refs[refName];
        let popper = ref.$refs.popper;
        if (popper.$el) popper = popper.$el;
        if (
          !Array.from(popper.children).some(
            (v) => v.className === "el-cascader-menu__list"
          )
        ) {
          const el = document.createElement("ul");
          el.className = "el-cascader-menu__list";
          el.style =
            "border-top: solid 1px #E4E7ED; padding:0;color: #4f7bff;cursor: pointer;";
          el.innerHTML = `<li class="el-cascader-node" style="height:38px;line-height: 38px">
                          <span class="el-cascader-node__label">+新增一级商家</span>
                          </li>`;
          popper.appendChild(el);
          el.onclick = () => {
            if (this.managerType != 0 || getAuth("seniorOperate")) {
              this.$refs.seniorModal.isShow();
            } else {
              this.$message({
                message: "当前用户没有权限新增一级商家",
                type: "warning",
              });
            }
          };
        }
      }
    },
    getNewSeniorName(item) {
      this.seniorRemoteMethod(item.senior_name);
    },
    async getInfoBusiness() {
      const parme = {
        business_id: this.business_id,
      };
      const { code, data } = await infoBusiness(parme);
      if (code == 200) {
        this.businessForm = {
          ...data,
          min_age: data.apply_bill_min_age,
          max_age: data.apply_bill_max_age,
        };
        this.getSelectSenior(data.senior_name);
        this.getSelectEntity(data.entity_name);
      }
    },
    confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res;
          this.business_id
            ? (res = await editBusiness(this.businessForm))
            : (res = await createBusiness(this.businessForm));
          if (res.code == 200) {
            this.close();
            this.$message.success("操作成功");
            this.$parent.initData();
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.businessForm = {
        exceed_fee: 0,
        extend_fee: 0,
        auto_confirm: 0,
        skip_bind_bank: 0,
        skip_ocr: 0,
        discount_bill: 0,
        refund_bill: 0,
        refund_bill_num: 0,
        first_pay_day: 0,
        exceed_fee_day: 0,
        dalay_day: 0,
        manage_product: 0,
        is_business_fee_extend: 0,
        manage_package: 0,
        min_age: 18,
        max_age: 55,
        customer_service_phone:""
      };
      this.seniorList = [];
      this.entityList = [];
      this.$refs.businessForm.resetFields();
    },
    handleClose(done) {
      done();
      this.close();
    },
    async getSelectEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data;
      }
    },
    onEntityChange(val) {
      this.businessForm.entity_id = val;
    },
    onEntityClear() {
      this.businessForm.entity_id = "";
      this.entityList = [];
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    onSeniorChange(val) {
      this.businessForm.senior_id = val;
    },
    onSeniorClear() {
      this.businessForm.senior_id = "";
      this.seniorList = [];
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .classification {
    margin: 10px 40px;
    .content-form {
      margin: 0px 10px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>