export const businessCol = [
  {
    label: '商家名称',
    prop: 'full_name',
    align: 'center'
  },
  {
    label: '法律主体',
    prop: 'contract_layer_name',
    align: 'center'
  },
  {
    label: '签约主体',
    prop:'entity_name',
    align: 'center'
  },
  {
    label: '所属一级商家',
    prop:'senior_name',
    align: 'center'
  },
  {
    label: '推荐码',
    prop: 'recommend_code',
    align: 'center',
    width: 80,
  },
  {
    label: '申办年龄',
    prop: 'age_text',
    align: 'center',
    width: 80,
  },
  {
    label: '商家电话',
    prop: 'service_phone',
    align: 'center'
  },
  {
    label: '客服电话',
    prop: 'customer_service_phone',
    align: 'center'
  },
  {
    label: '创建人',
    prop:'create_user_name',
    align: 'center'
  },
  {
    label: '创建时间',
    prop:'create_time',
    align: 'center'
  },
  {
    label: '更新人',
    prop:'update_user_name',
    align: 'center'
  },
  {
    label: '更新时间',
    prop:'update_time',
    align: 'center'
  },
  {
    label: '审核状态',
    slot:'audit_status'
  },
  {
    label: '操作',
    slot:'operation'
  },
]

export default {
  businessCol
}