import request from '@/utils/request'

// 创建一级机构
export function createSenior (params) {
  return request.post('/api/createSenior', params)
}

// 修改一级机构
export function editSenior (params) {
  return request.post('/api/editSenior', params)
}

// 一级机构列表
export function listSenior (params) {
  return request.post('/api/listSenior', params)
}

// 删除禁用一级机构
export function statusSenior (params) {
  return request.post('/api/statusSenior', params)
}

// 一级机构详情
export function infoSenior (params) {
  return request.post('/api/infoSenior', params)
}