<template>
  <div class="qrCodeImg">
    <el-dialog :visible.sync="showQRcodeImg" width="300px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="固定二维码" name="immobilization">
          <div class="Qrcode" ref="qrcode"></div>
        </el-tab-pane>
        <el-tab-pane label="随机二维码" name="random">
          <div class="Qrcode" ref="randcode"></div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/utils/config";
import Qrcode from "qrcodejs2";

export default {
  name: "AdminQRCodeImg",

  data() {
    return {
      showQRcodeImg: false,
      activeName: "immobilization",
      business_id: "",
    };
  },

  mounted() {},

  methods: {
    isShow(business_id) {
      this.showQRcodeImg = true;
      this.business_id = business_id;
      this.$nextTick(async () => {
        var url = config.OR_CODE_URL + "?businessId=" + business_id;
        this.creatCode(url);
      });
    },
    handleClick() {
      if (this.activeName == "random") {
        var rand = Math.floor(Math.random() * 900) + 100;
        var url =
          config.OR_CODE_URL +
          "?businessId=" +
          this.business_id +
          "&random=" +
          rand;
        this.creatCode(url);
      }
    },
    creatCode(url) {
      if (this.activeName == "random") {
        this.$refs.randcode.innerHTML = "";
        new Qrcode(this.$refs.randcode, {
          text: url,
          colorDark: "#333",
          colorLight: "#fff",
          correctLevel: Qrcode.CorrectLevel.L,
        });
      } else {
        this.$refs.qrcode.innerHTML = "";
        new Qrcode(this.$refs.qrcode, {
          text: url,
          colorDark: "#333",
          colorLight: "#fff",
          correctLevel: Qrcode.CorrectLevel.L,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qrCodeImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>