import request from '@/utils/request'

// 银行编码
export function listBank (params) {
  return request.post('/api/listBank', params)
}

// 全国省直辖市列表
export function listProvince (params) {
  return request.post('/api/listProvince', params)
}

// 省包含的市列表
export function listCity (params) {
  return request.post('/api/listCity', params)
}

// 市包含的区县列表
export function listDistrict (params) {
  return request.post('/api/listDistrict', params)
}

// 主体下拉搜索
export function selectEntity (params) {
  return request.post('/api/selectEntity', params)
}

// 一级机构下拉搜索
export function selectSenior (params) {
  return request.post('/api/selectSenior', params)
}

// 商家下拉搜索
export function selectBusiness (params) {
  return request.post('/api/selectBusiness', params)
}

// 产品下拉搜索
export function selectProduct (params) {
  return request.post('/api/selectProduct', params)
}

// 平台账号下拉列表
export function selectListAccount (params) {
  return request.post('/api/selectListAccount', params)
}