<template>
  <div class="businessList">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="140px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item
              label="商家名称："
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-input
                v-model="searchForm.business_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="一级机构"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.senior_id"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                @change="onSeniorChange"
                @clear="onSeniorClear"
                clearable
                filterable
                remote
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.senior_id"
                  :label="item.senior_name"
                  :value="item.senior_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="商家主体"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.entity_id"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                @change="onEntityChange"
                @clear="onEntityClear"
                clearable
                filterable
                remote
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.entity_id"
                  :label="item.entity_name"
                  :value="item.entity_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="审核状态"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.audit_status"
                clearable
              >
                <el-option
                  v-for="item in auditStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div>
            <el-button type="primary" size="small" @click="serchData"
              >查询</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="derive">
      <el-button
        size="small"
        type="primary"
        @click="createBusiness()"
        v-if="managerType != 0 || getAuth('businessOperate')"
        >添加</el-button
      >
    </div>
    <div>
      <base-table
        :columns="businessCol"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="initData"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          width="80px"
          slot="audit_status"
          label="审核状态"
          align="center"
          :formatter="auditStatusFormatter"
        ></el-table-column>
        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="onORCode(scope.row)"
              >二维码</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('businessOperate')"
              @click="uploadContract(scope.row)"
              >合同</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('quotedPriceList')) &&
                scope.row.audit_status == '1'
              "
              @click="editQuotedPrice(scope.row)"
              >报价</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('productList')) &&
                scope.row.audit_status == '1'
              "
              @click="editProduct(scope.row)"
              >产品</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('businessOperate')"
              @click="editBusiness(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                (managerType != 0 || getAuth('auditBusiness')) &&
                scope.row.audit_status != '1'
              "
              @click="auditBusiness(scope.row)"
              >审核</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('businessOperate')"
              @click="delBusiness(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </base-table>
    </div>
    <businessModal ref="businessModal" />
    <orCodeImg ref="orCodeImg" />
    <upload-contract ref="uploadContract"/>
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      element-loading-text="商家正在审核中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </div>
</template>

<script>
import columns from "./columns";
import businessModal from "./components/business-modal.vue";
import { auditStatusList } from "@/assets/equipment/allSelect.json";
import { listBusiness, statusBusiness, auditBusiness } from "@/api/business.js";
import { selectSenior, selectEntity } from "@/api/selectfun.js";
import orCodeImg from "./components/QR-code-img.vue";
import { getAuth } from "@/utils/index.js";
import UploadContract from './components/upload-contract.vue';
export default {
  name: "businessList",
  components: { businessModal, orCodeImg, UploadContract },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      auditStatusList: auditStatusList,
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        business_name: "",
      },
      senior_id: "",
      entity_id: "",
      fullscreenLoading: false,
    };
  },

  mounted() {
    this.senior_id = this.$route.query.senior_id;
    if (this.senior_id) {
      this.searchForm.senior_id = Number(this.senior_id);
      this.seniorRemoteMethod(this.$route.query.senior_name);
    }
    this.entity_id = this.$route.query.entity_id;
    if (this.entity_id) {
      this.searchForm.entity_id = Number(this.entity_id);
      this.entityRemoteMethod(this.$route.query.entity_name);
    }
    this.initData();
    // this.getSelectEntity();
    // this.getSelectSenior();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      const { code, data } = await listBusiness(this.searchForm);
      if (code == 200) {
        this.tableData = data.data.map(item => {
          item.age_text = item.apply_bill_min_age+' ~ '+item.apply_bill_max_age
          return item
        });
        this.total = data.total;
      }
    },
    uploadContract(row){
      this.$refs.uploadContract.isShow(row);
    },
    
    onORCode(row) {
      this.$refs.orCodeImg.isShow(row.business_id);
    },
    createBusiness() {
      this.$refs.businessModal.isShow('add','');
    },
    editBusiness(row) {
      this.$refs.businessModal.isShow('edit',row);
    },
    editQuotedPrice(row) {
      this.$router.push({
        path: "/business/quoted-price",
        query: {
          business_id: row.business_id,
        },
      });
    },
    editProduct(row) {
      const routeData = this.$router.resolve({
        path: "/product/productList",
        query: {
          business_id: row.business_id,
          business_name: row.full_name,
        },
      });
      window.open(routeData.href, "_blank");
    },
    delBusiness(row) {
      this.$confirm("是否删除当前商家？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            business_id: row.business_id,
            status: 2,
          };
          const { code } = await statusBusiness(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除完成!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
    auditBusiness(row) {
      this.$confirm("是否对当前商家进行审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.fullscreenLoading = true;
          const parmes = {
            business_id: row.business_id,
            audit_status: 1,
          };
          const { code } = await auditBusiness(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "审核完成!",
            });
            this.initData();
            this.fullscreenLoading = false;
          } else {
            this.fullscreenLoading = false;
          }
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    onEntityChange(val) {
      this.searchForm.entity_id = val;
      this.initData();
    },
    onEntityClear() {
      this.searchForm.entity_id = "";
      this.entityList = [];
      this.initData();
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    async getSelectEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data;
      }
    },
    onSeniorChange(val) {
      this.searchForm.senior_id = val;
      this.initData();
    },
    onSeniorClear() {
      this.searchForm.senior_id = "";
      this.seniorList = [];
      this.initData();
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data;
      }
    },
    auditStatusFormatter(row) {
      switch (row.audit_status) {
        case 0:
          return "未审核";
        case 1:
          return "审核通过";
        case 2:
          return "审核拒绝";
        default:
          return "其他";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.businessList {
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>