<template>
  <div>
    <el-dialog
      title="合同"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" size="small" :rules="rules">
        <el-form-item
          label="合同名称"
          label-width="100px"
          prop="contractName"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="form.contractName"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="文件上传"
          label-width="100px"
          style="margin-bottom: 15px"
        >
          <el-upload
            action=""
            :before-remove="beforeRemove"
            :on-preview="handlePreview"
            :limit="1"
            :on-exceed="handleExceed"
            :before-upload="handleUpload"
            :http-request="fnUploadRequest"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button size="small" v-if="showDelBtn" @click="delContract()" type="primary"
          >删除合同</el-button
        >
        <el-button type="primary" @click="confirm('form')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { businessContract } from "@/api/business.js";
import { getOSSConf } from "@/api/util.js";
import axios from "@/utils/request";
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        contractName: "",
      },
      fileList: [],
      rules: {
        contractName: [
          { required: true, message: "请输入文件名", trigger: "blur" },
        ],
      },
      businessId: "",
      showDelBtn:false
    };
  },
  methods: {
    async isShow(row) {
      this.dialogFormVisible = true;
      this.form.businessId = row.business_id;
      this.businessId = row.business_id;
      this.showDelBtn = false
      if (row.contract_image != "" && row.contract_name != "") {
        this.showDelBtn = true
        this.form.contractName = row.contract_name;
        this.form.contractImage = row.contract_image;
        this.fileList = [
          {
            name: row.contract_name,
            url: row.contract_image,
          },
        ];
      }
    },
    async delContract() {
      const parmes = {
        businessId: this.businessId,
        contractName: "",
        contractImage: "",
      };
      const { code } = await businessContract(parmes);
      if (code == 200) {
        this.close();
        this.$message.success("操作成功");
        this.$parent.initData();
      }
    },
    handleUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "pdf" || testmsg === "PDF";
      var bool = false;
      if (extension) {
        bool = true;
      } else {
        bool = false;
      }
      if (!extension) {
        this.$message.error("请选择PDF文件！");
        return bool;
      }
      return bool;
    },
    async fnUploadRequest(options) {
      const parma = {
        dirType: 1,
      };
      const { data } = await getOSSConf(parma);
      var extension = "";
      if (/.*(\.[^\\.]*)/.test(options.file.name)) {
        extension = RegExp.$1;
      }
      const filename = data.filename;
      const osspath = data.policy.dir + filename + extension;

      let formData = new FormData();
      formData.append("key", osspath);
      formData.append("OSSAccessKeyId", data.policy.accessid);
      formData.append("policy", data.policy.policy);
      formData.append("signature", data.policy.signature);
      formData.append("success_action_status", 200);
      formData.append("file", options.file);

      axios({
        url: data.policy.host,
        method: "post",
        processData: false,
        data: formData,
      })
        .then(() => {
          this.fileList.push({
            name: options.file.name,
            url: data.domain + "/" + osspath,
            path: osspath,
          });
        })
        .catch(() => {});
    },
    handlePreview(file) {
      window.open(file.url, "_blank");
    },
    beforeRemove() {
      this.fileList = [];
    },
    handleExceed() {
      this.$message.warning("限制上传数量1个文件以内！");
    },
    async confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.fileList.length != 0) {
            this.form.contractImage = this.fileList[0].path;
            const { code } = await businessContract(this.form);
            if (code == 200) {
              this.close();
              this.$message.success("操作成功");
              this.$parent.initData();
            }
          } else {
            this.$message.warning("请上传文件");
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {};
      this.fileList = [];
      this.$refs.form.resetFields();
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>
