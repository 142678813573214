<template>
  <div>
    <el-dialog
      :title="title"
      width="770px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form ref="seniorForm" :model="seniorForm" size="small" :rules="rules">
        <el-form-item
          label="机构名称"
          label-width="100px"
          prop="senior_name"
          style="margin-bottom: 0px"
        >
          <el-input
            v-model="seniorForm.senior_name"
            maxlength="50"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('seniorForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createSenior, editSenior, infoSenior } from "@/api/senior.js";

export default {
  name: "seniorModal",
  data() {
    return {
      dialogFormVisible: false,
      title: "新增机构",
      seniorForm: {
        senior_name: "",
      },
      senior_id: "",
      rules: {
        senior_name: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async isShow(type, val) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.title = "修改机构";
        this.senior_id = val.senior_id;
        this.getInfoSenior();
      } else {
        this.title = "新增机构";
        this.senior_id = "";
      }
    },
    async getInfoSenior() {
      const parme = {
        senior_id: this.senior_id,
      };
      const { code, data } = await infoSenior(parme);
      if (code == 200) {
        this.seniorForm = {
          senior_id: data.senior_id,
          senior_name: data.senior_name,
        };
      }
    },
    confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res;
          this.senior_id
            ? (res = await editSenior(this.seniorForm))
            : (res = await createSenior(this.seniorForm));
          if (res.code == 200) {
            this.close();
            this.$message.success("操作成功");
            if (this.senior_id) {
              this.$parent.initData();
            } else {
              this.$emit("getNewSeniorName", res.data);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.seniorForm = {
        senior_name: "",
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .classification {
    margin: 10px 40px;
    .content-form {
      margin: 0px 10px;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>
  