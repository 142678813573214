import request from '@/utils/request'

// 获取OSS配置信息
export function getOSSConf (params) {
  return request.post('/api/getOSSConf', params)
}

// 获取律师函信息
export function getLawyer (params) {
  return request.post('/api/lawyer', params)
}

export function contractInfo(params) {
  return request.post('api/contractInfo', params)
}