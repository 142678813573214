import request from '@/utils/request'

// 创建商家
export function createBusiness (params) {
  return request.post('/api/createBusiness', params)
}

// 修改商家
export function editBusiness (params) {
  return request.post('/api/editBusiness', params)
}

// 商家列表
export function listBusiness (params) {
  return request.post('/api/listBusiness', params)
}

// 删除禁用商家
export function statusBusiness (params) {
  return request.post('/api/statusBusiness', params)
}

// 商家审核
export function auditBusiness (params) {
  return request.post('/api/auditBusiness', params)
}

// 商家详情
export function infoBusiness (params) {
  return request.post('/api/infoBusiness', params)
}

//商家上传合同
export function businessContract (params) {
  return request.post('/api/businessContract', params)
}



